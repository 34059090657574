import React from "react";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import img from "../../images/2024.jpg";

const ImagePopup = ({ show, handleClose }) => {
  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    border: "none",
    cursor: "pointer",
    fontSize: "40px",
    color: "#fff",
    zIndex: "1",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    padding: "5px",
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={handleClose}
      contentLabel="Image Popup"
      style={{
        content: {
          backgroundColor: "transparent",
          border: "none",
          marginTop: "auto",
          maxWidth: "90vw",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex:"11"
        },
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <img
        src={img}
        alt="Popup Image"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          position: "relative",
        }}
      />
      <AiOutlineClose style={{ ...closeButtonStyle }} onClick={handleClose} />
    </Modal>
  );
};

export default ImagePopup;
