import React from 'react'
import LoginHero from '../../components/loginPage/LoginHero'

const Login = () => {
  return (
    <div>
      <LoginHero />
    </div>
  )
}

export default Login
