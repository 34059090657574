import './BlankStyles.css'
import React from 'react'

const Blank = () => {
  return (
    <div className='blank'>
        <br />
        <br />
        <br />
        <br />
        <br />
    </div>
  )
}

export default Blank

