import './FacultyHeaderStyles.css'
import React from 'react'

const FacultyHeader = () => {
  return (
    <div className='faculty-header'>
        <h2>Faculty</h2>
        <p>Lorem ipsum dolor sit amet. Sed impedit quaerat id velit explicabo ut praesentium quidem 33 consequatur vero et maiores distinctio est numquam dolorem et quia voluptatum. Lorem ipsum dolor sit amet. Sed impedit quaerat id velit explicabo ut praesentium quidem 33 consequatur vero et maiores distinctio est numquam dolorem et quia voluptatum.</p>
    </div>
  )
}

export default FacultyHeader
